import './Unternehmen.css'












import designimg from '../../bilder/design/bild-ws.png';
import { useTranslation } from 'react-i18next';



export default function Unternehmen() {
    const { t } = useTranslation();


    return (
        <>
            <div className='unternehmen-container'>
                <div className='image-container'>
                    <img id="messebau-img" alt="messebau-bild" src={designimg} />
                    <span className='heading'>{t('company.unternehmen')}</span>
                </div>
                <p className='align-text heading-two'>{t('company.quote')}</p>
                <p className='description-text'>

                    {t('company.text1')}<br />
                    {t('company.text2')}<br /><br />
                    {t('company.text3')}<br /><br />
                    {t('company.text4')}<br /><br />
                </p>
            </div>

        </>
    )
}